@for (theme of themes; track $index) {
    <button mat-menu-item (click)="selectTheme($event, theme.name)">
        <mat-icon [color]="currentTheme === theme ? 'accent' : undefined">{{
            currentTheme === theme
                ? 'radio_button_checked'
                : 'radio_button_unchecked'
        }}</mat-icon>
        <span> {{ theme.displayName }}</span>
    </button>
}
